





































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import Common from '../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Datepicker from 'vuejs-datepicker'
import {ja} from 'vuejs-datepicker/dist/locale'
import removeMd from 'remove-markdown'
import moment from 'moment'

@Component({
  components: {
    ClipLoader,
    Datepicker
  },
})
export default class Home extends Vue {
  MAX_LOOM_COUNT = 10
  MAX_NOTICE_COUNT = 5
  rMd = removeMd
  isLoading = true
  language = ja
  userInfo: any = {}
  rooms: any[] = []
  notices: any[] = []
  questions: any[] = []
  classes: any[] = []
  imageUrl: any = ''
  point = 0
  freePoint = 0
  disabledDates = {to: new Date()}
  selectSchedule: any = null
  schedules: any[] = []
  viewSchedules: any[] = []
  scheduleDates: any[] = []
  dateHighlighted = {
    customPredictor: (date: Date) => {
      let year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()
      return this.scheduleDates.some((value) => {
        return value.getFullYear() == year
                && value.getMonth() == month
                && value.getDate() == day
      })
    }
  }

  beforeMount() {
    this.disabledDates.to.setDate(this.disabledDates.to.getDate() - 1)
  }

  mounted() {
    sessionStorage.removeItem('user')
    let promise = []
    promise.push(new Promise((resolve) => {
      Common.getUserInfo().then((userInfo) => {
        this.userInfo = userInfo
        this.userInfo.clinicRateIcons = Common.createRateIcon(this.userInfo.clinicRate)
        this.userInfo.studentRateIcons = Common.createRateIcon(this.userInfo.studentRate)
        this.imageUrl = Common.createUserIconURL(this.userInfo.uid, this.userInfo.fileExist)
        resolve()
      })
    }))

    let uid = Common.getUserId()

    promise.push(new Promise((resolve) => {
      let date = new Date()
      date.setHours(date.getHours() - 3)
      firebase.firestore().collectionGroup('schedules').where('uid', '==', uid).where('startTime', '>=', date).orderBy('startTime').get().then((data) => {
        data.forEach((value) => {
          let schedule = value.data()!
          schedule.startTime = schedule.startTime.toDate()
          schedule['onayamiId'] = value.ref.parent.parent!.parent.parent!.id
          schedule['roomId'] = value.ref.parent.parent!.id
          this.schedules.push(schedule)
          this.viewSchedules.push(schedule)
          this.scheduleDates.push(schedule.startTime)
          firebase.firestore().collection('users').doc(schedule.partnerUid).get().then((data) => {
            let user = data.data()!
            this.$set(schedule, 'partnerName', user.name)
            this.$set(schedule, 'url', Common.createUserIconURL(data.id, user.fileExist))
          })
        })
      }).finally(() => {
        resolve()
      })
    }))

    promise.push(new Promise((resolve) => {
      let roomPromises:any = []
      firebase.firestore().collectionGroup('rooms').where('uids', 'array-contains', Common.getUserId()).orderBy('chatNum', 'desc').limit(this.MAX_LOOM_COUNT).get().then((rooms) => {
        rooms.forEach((room) => {
          let result = room.data()
          let partnerUid = result.uids[0] == uid ? result.uids[1] : result.uids[0]
          if (result.chatUid == partnerUid && result.chatNum != 0) {
            let isClinic = result.clinic == uid

            let obj = <any>{id: room.id, onayamiId: room.ref.parent.parent!.id, status: result.status, startTime: result.startTime, isClinic: isClinic, chatNum: result.chatUid != uid ? result.chatNum : 0, chat: result.chatText, date: (result.chatCreateTime ? result.chatCreateTime.toDate() : ''), uids: result.uids}
            this.rooms.push(obj)
            this.getRoomInfo(roomPromises, partnerUid, obj)
          }
        })
        this.rooms.sort(function(a, b) {
          return b.date.getTime() - a.date.getTime()
        })
        Promise.all(roomPromises).finally(() => {resolve()})
      })
    }))

    promise.push(new Promise((resolve) => {
      let noticePromises:any = []
      let noticeArray: any[] = []
      let newsArray: any[] = []
      noticePromises.push(new Promise((noticeResolve) => {
        firebase.firestore().collection('users').doc(Common.getUserId()).collection('news').orderBy('createTime', 'desc').limit(this.MAX_NOTICE_COUNT).get().then((notices) => {
          notices.forEach((notice) => {
            let result = notice.data()
            result.id = notice.id
            result.url = Common.createUserIconURL(result.uid, true)
            Common.imageExist(result.url).catch(() => result.url = '')
            result.createTime = result.createTime.toDate()
            if (result.updateTime) {
              result.updateTime = result.updateTime.toDate()
            }
            noticeArray.push(result)
          })
          noticeResolve()
        })
      }))

      noticePromises.push(new Promise((noticeResolve) => {
        firebase.firestore().collection('news').where('public', '==', true).orderBy('createTime', 'desc').limit(this.MAX_NOTICE_COUNT).get().then((news) => {
          news.forEach((n) => {
            let result = n.data()
            result.id = n.id
            result.isNews = true
            result.createTime = result.createTime.toDate()
            result.updateTime = result.updateTime.toDate()
            noticeArray.push(result)
          })
          noticeResolve()
        })
      }))

      Promise.all(noticePromises).finally(() => {
        this.notices = noticeArray.concat(newsArray)
        this.notices.sort(function(a, b) {
          return b.createTime.getTime() - a.createTime.getTime()
        })
        this.notices = this.notices.slice(0, this.MAX_NOTICE_COUNT)
        resolve()
      })
    }))

    promise.push(new Promise((resolve) => {
      firebase.firestore().collection('onayamis').where('type', '==', 'question').orderBy('createTime', 'desc').limit(10).get().then((data) => {
        data.forEach((onayami) => {
          let value = onayami.data()
          let obj: any = {id: onayami.id, title: value.title, detail: value.detail, tags: value.tags, date: value.date, reward: value.reward, category: value.category, finish:value.finish, roomCount: value.roomCount ? value.roomCount : 0, userName: value.userName, createTime: value.createTime.toDate(), uid: value.uid}
          obj.url = Common.createUserIconURL(obj.uid, value.userFileExist)
          this.questions.push(obj)
        })
        resolve()
      })
    }))

    promise.push(new Promise((resolve) => {
      firebase.firestore().collection('onayamis').where('type', '==', 'class').where('finish', '==', false).orderBy('createTime', 'desc').limit(10).get().then((data) => {
        data.forEach((onayami) => {
          let value = onayami.data()
          let obj: any = {id: onayami.id, title: value.title, detail: value.detail, tags: value.tags, date: value.date, reward: value.reward, category: value.category, finish:value.finish, roomCount: value.roomCount ? value.roomCount : 0, userName: value.userName, rate: value.rate, rateNum: value.rateNum, restSlot: value.peopleLimit - value.inClass, createTime: value.createTime.toDate(), uid: value.uid}
          obj.url = Common.createUserIconURL(obj.uid, value.userFileExist)
          obj.rateIcons = Common.createRateIcon(obj.rate)
          this.classes.push(obj)
        })
        resolve()
      })
    }))

    Promise.all(promise).finally(() => {
      this.isLoading = false
    })
  }

  getRoomInfo(promises: any[], partnerUid: string, obj: any) {
    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('users').doc(partnerUid).get().then((user) => {
        let userData = user.data()!
        this.$set(obj, 'name', userData.name)
        this.$set(obj, 'url', Common.createUserIconURL(partnerUid, userData.fileExist))
      }).finally(() => {
        resolve()
      })
    }))
    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('onayamis').doc(obj.onayamiId).get().then((onayami) => {
        this.$set(obj, 'title', onayami.data()!.title)
      }).finally(() => {
        resolve()
      })
    }))
  }

  selectedSchedule(date: Date) {
    if (this.selectSchedule 
      && this.selectSchedule.getFullYear() == date.getFullYear()
      && this.selectSchedule.getMonth() == date.getMonth()
      && this.selectSchedule.getDate() == date.getDate()) {
      this.viewSchedules = this.schedules
      setTimeout(() => {
        this.selectSchedule = null
      })
      return
    }
    let year = date.getFullYear()
    let month = date.getMonth()
    let day = date.getDate()
    this.viewSchedules = this.schedules.filter((schedule) => {
      return schedule.startTime.getFullYear() == year
              && schedule.startTime.getMonth() == month
              && schedule.startTime.getDate() == day
    })
  }

  select(question: firebase.firestore.DocumentData) {
    this.$router.push(`/onayamis/${question.id}`)
  }

  selectClass(question: firebase.firestore.DocumentData) {
    this.$router.push(`/onayami/${question.id}`)
  }

  selectRoom(room: firebase.firestore.DocumentData) {
    this.$router.push(`onayamis/${room.onayamiId}/chat/${room.id}`)
  }

  selectNotice(notice: firebase.firestore.DocumentData) {
    if (notice.isNews) {
      this.$router.push('/news/' + notice.id)
    } else {
      this.$router.push(notice.to)
    }
    
  }

  clickSchedule(schedule: any) {
    this.$router.push(`onayamis/${schedule.onayamiId}/chat/${schedule.roomId}`)
  }
}
